import Vue from 'vue'
import moment from 'moment'

Vue.filter('phoneFormatHide', function (value) {
  if (!value) { return '' }
  value = '0' + value.substring(2)
  value = value.substring(0, 4) + ' ' + value.substring(4)
  value = value.substring(0, value.length - 3) + ' xxx'
  return value
})

Vue.filter('moneyFormat', function (value) {
  if (!value) { return 0 }
  return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
})

Vue.filter('moneyFormatCoin', function (value) {
  if (!value) { return 0 }
  return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
})

Vue.filter('phoneFormat', function (value) {
  if (!value) { return '' }
  value = '0' + value.substring(2)
  value = value.substring(0, 4) + ' ' + value.substring(4, 7) + ' ' + value.substring(7)
  return value.substring(0, 13)
})

Vue.filter('formatDate', function (value) {
  if (!value) { return '' }
  value = moment.unix(value).format('DD/MM/YYYY')
  return value
})

Vue.filter('formatDateDetail', function (value) {
  if (!value) { return '' }
  const value1 = moment.unix(value).format('HH:mm')
  const value2 = moment.unix(value).format('DD/MM/YYYY')
  value = value1 + ' ' + value2
  return value
})

Vue.filter('formatDateOnlyTime', function (value) {
  if (!value) { return '' }
  const value1 = moment.unix(value).format('HH:mm')
  value = value1 + 'h'
  return value
})

Vue.filter('formatDateTime', function (value) {
  if (!value) { return '' }
  value = moment(value).format('HH:mm - DD/MM/YYYY')
  return value
})

Vue.filter('checkUrl', function (str = '') {
  const pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/
  const res = str.split(' ')
  let result = ''
  window.$.each(res, function (key, val) {
    if (pattern.test(val)) {
      result += '<a href="' + val + '" target="_blank" style="color:white;">' + val + '</a>' + ' '
    } else {
      result += val + ' '
    }
  })
  return result
})

Vue.filter('ralativeTime', function (timeStamp) {
  const time = timeStamp * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return 'Vừa xong'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + ' phút' + ' trước'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + ' giờ' + ' trước'
  } else if (diff < 3600 * 24 * 2) {
    return '1 ngày trước'
  } else {
    return [
      formatNumber(d.getHours()),
      formatNumber(d.getMinutes())
    ].join(':') + ' ' +
            [
              formatNumber(d.getDate()),
              formatNumber((d.getMonth() + 1)), d.getFullYear()
            ].join('/')
  }
})

Vue.filter('parseTime', function (time, cFormat) {
  if (!time) {
    return null
  }
  if (arguments.length === 0) {
    return null
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time) * 1000
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }

    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }

    return value || 0
  })

  return timeStr
})
Vue.filter('hightLight', function (string, keyword) {
  const index = string.indexOf(keyword)
  if (index >= 0) {
    string = string.substring(0, index) + "<span class='highlight'>" + string.substring(index, index + keyword.length) + '</span>' + string.substring(index + keyword.length)
  }
  return string
})
export function formatNumber (number) {
  return number < 10 ? '0' + number : number
}
Vue.filter('duration', function (seconds) {
  if (seconds <= 0) {
    return '00:00'
  }
  let h = Math.floor(seconds / 86400)
  h = h >= 10 ? h : '0' + h
  let m = Math.floor(seconds / 60)
  m = m >= 10 ? m : '0' + m
  let s = Math.floor(seconds % 60)
  s = s >= 10 ? s : '0' + s
  return h === '00' ? (m + ':' + s) : (h + ':' + m + ':' + s)
})
Vue.filter('durationTopic', function (seconds) {
  if (seconds <= 0) {
    return '0 giây'
  }
  const arr = []
  let h = Math.floor(seconds / 86400)
  if (h > 0) {
    h = h >= 10 ? h : '0' + h
    arr.push(h + ' giờ')
  }
  let m = Math.floor(seconds / 60)
  if (m > 0) {
    m = m >= 10 ? m : '0' + m
    arr.push(m + ' phút')
  }
  let s = Math.floor(seconds % 60)
  if (s > 0) {
    s = s >= 10 ? s : '0' + s
    arr.push(s + ' giây')
  }
  return arr.join(' ')
})
