/* eslint-disable no-undef */
export default ({app}) => {
  /*
   ** Only run on client-side and only in production mode
   */
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  const script = document.createElement('script')
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-1S3PFDT38Y'
  script.async = true
  document.getElementsByTagName('head')[0].appendChild(script)
  window.dataLayer = window.dataLayer || []

  function gtag () {
    dataLayer.push(arguments)
  }

  gtag('js', new Date())
  gtag('config', 'G-1S3PFDT38Y')
  gtag('event', 'page_view', {
    send_to: 'G-1S3PFDT38Y'
  })
}
